<template lang="html">
	<div class="container-fluid p-5">
		<loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header">
						<h6>ပစ္စည်း အမျိုးအစား ထည့်ရန် <small>(Fields in red are required)</small></h6>
					</div>
					<div class="card-body">
						<form v-on:submit.prevent="onSubmit">
							<div class="form-group row">
								<label for="inputName" class="col-sm-2 col-form-label text-danger">အမျိုးအစား နာမည်</label>
								<div class="col-sm-10">
									<input type="text" v-model="itemRequest.name" @blur="validationRule()"
										class="form-control" id="inputName">
									<small class="text-danger">{{validationData.name}}</small>
								</div>
							</div>

							<div class="form-group row">
								<label for="inputSlug" class="col-sm-2 col-form-label text-danger">အမျိုးအစား ကုဒ်နံပါတ်</label>
								<div class="col-sm-10">
									<input type="text" v-model="itemRequest.slug" @blur="validationRule()"
										class="form-control" id="inputSlug">
									<small class="text-danger">{{validationData.slug}}</small>
								</div>
							</div>

							<button type="submit" class="btn btn-primary float-save">လုပ်ဆောင်မည်</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
	import {
		mapState,
		mapActions
	} from 'vuex'
	import env from '../../enviorments'
	import Loading from 'vue-loading-overlay'
	import 'vue-loading-overlay/dist/vue-loading.css';
	export default {
		name: 'itemClothCreate',
		components: {
			Loading
		},
		data() {
			return {
				env,
				isLoading: false,
				fullPage: true,
				itemRequest: {
					name: "",
					slug: "",
				},
				validationData: {
					name: "",
					slug: "",
				}
			}
		},
		watch: {
			async $route(to, from) {
				if (to.params.id) {
					this.itemRequest.id = to.params.id
					await this.itemDetail()
				}
			},
		},
		computed: {},
		methods: {
			...mapActions({
				itemClothStoreAction: 'itemClothStoreAction',
			}),
			async onSubmit() {
				this.isLoading = true
				let validation = this.validationRule()
				if (validation == true) {
					this.isLoading = false
					return
				}
				let option = {
					name: this.itemRequest.name,
					slug: this.itemRequest.slug,
				}
				await this.itemClothStoreAction({
					...option
				}).then(res => {
					this.$router.replace({ path: 'item-cloth-list', query: { page: 1, reverse: 'desc', sort: 'created_at', name: ''} }).catch(()=>{})
					this.isLoading = false;
				}).catch(err => this.isLoading = true)
			},
			validationRule() {
				let isValidation = []
				if (this.itemRequest.name == "") {
					this.validationData.name = "ပစ္စည်းအမည် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.name = ""
					isValidation.push(false)
				}
				if (this.itemRequest.slug == "") {
					this.validationData.slug = "ပစ္စည်းအမည် ကုဒ်နံပါတ် ဖြည့်ပါ။"
					isValidation.push(true)
				} else {
					this.validationData.slug = ""
					isValidation.push(false)
				}
				if (isValidation.includes(true)) {
					return true
				} else {
					return false
				}
			}
		},
		async mounted() {
		}
	}
</script>

<style scoped lang="scss">
	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 56px;
	}
	.banner-image {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}
</style>